@mixin keyFrameCircle($radius, $animation_duration, $animation_delay) {
  $pi: 22 / 7;
  $circumference: 2 * $pi * $radius;
  stroke-dasharray: $circumference;
  stroke-dashoffset: 0;
  animation: circle-fill-#{$radius} #{$animation_duration}s linear #{$animation_delay}s;
  animation-fill-mode: forwards;

  @keyframes circle-fill-#{$radius} {
    0% {
      stroke-dashoffset: $circumference;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}

@keyframes animateWings {
  0% {
    transform: translate(-15px, -15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes animateStars {
  0% {
    background-position-x: -5px;
  }
  50% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -5px;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    //transform: translate3d(-50%, 0, 0);
  }
  100% {
    opacity: 1;
    //transform: translate3d(0, 0, 0);
  }
}
