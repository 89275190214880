@import '../../../../assets/css/base/media-queries';
@import '../../../../assets/css/base/mixin';

.modalCover .buyPackModal {
  width: 864px;
  padding: 16px;
  @apply bg-main-bg;

  .body {
    padding: 62px 32px 24px;
    @include breakpoint-down(lg) {
      padding: 0 0 24px;
    }
  }

  .section-title {
    margin-bottom: 24px;
    @include breakpoint-down(lg) {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      max-width: 80%;
      margin: 0 auto 24px;
    }
  }

  .range-slider {
    margin-bottom: 82px;
    @include breakpoint-down(lg) {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 46px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      @include breakpoint-down(lg) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 26px;
        text-align: center;
      }
    }

    .rc-slider {
      margin-bottom: 84px;
      height: unset;
      padding: unset;
      @include breakpoint-down(lg) {
        margin-bottom: 66px;
      }
      .rc-slider-rail {
        height: 8px;
        background: linear-gradient(90deg, #CD702C 0%, #9747FF 51.35%, #1F7AC8 100%);
        border-radius: 4px;
        display: block;
        position: relative;
        touch-action: none;
      }
      .rc-slider-track {
        height: 100%;
        background: rgba(29, 30, 38, 0.7);
        border-radius: 4px;
        top: 0;
        display: block;
      }
      .rc-slider-step {
        visibility: hidden;
      }
      .rc-slider-handle {
        width: 28px;
        height: 28px;
        background: #D9D9D9;
        border-radius: 30px;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        cursor: pointer;
        display: inline-block;
        position: absolute;
        touch-action: none;
        opacity: 1;
        margin-top: -14px;
        border: unset;
        &.rc-slider-handle-dragging {
          box-shadow: unset;
          border: unset;
        }

        &::after {
          content: unset;
        }
      }
      .rc-slider-mark {
        .rc-slider-mark-text {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          color: #FFFFFF;
          &:first-child {
            transform: unset !important;
          }
          &:last-child {
            transform: translateX(-100%) !important;
          }
        }
      }
    }
  }

  .btcpay-info {
    gap: 12px;

    p {
      @apply text-secondary;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
}
