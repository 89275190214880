@import "media-queries";
.responsive-table {
  overflow-x:auto;
  @include breakpoint-down(lg) {
    margin: 0 -16px;
    padding: 0 16px;
    width: 100%;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  &.history {
    padding: 0;
    width: unset;
  }
}

table {
  background: #1D1E26;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(12px);
  border-radius: 24px;
  width: 100%;
  overflow: hidden;

  thead {
    tr {
      border-bottom: 1px solid #23242B;
      background: rgba(35, 36, 43, 0.5);

      th {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #BCB8E3;
        padding: 16px;
        text-align: left;
        @include breakpoint-down(lg) {
          padding: 16px 8px;
        }

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #23242B;

      &:last-child {
        border-bottom: unset;
      }

      td {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        padding: 16px;
        text-align: left;
        white-space: nowrap;
        @include breakpoint-down(lg) {
          padding: 16px 8px;
        }

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
          text-align: center;

          svg {
            margin-right: 0;
          }
        }

        svg {
          display: inline;
          margin-right: 8px;
        }
      }
    }
  }
  .webgl {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .download_icon_cookies {
    cursor: pointer;
  }
  .download_icon_fps {
    cursor: pointer;
  }
  .download_icon_profile {
    cursor: pointer;
  }
  .check-td {
    border-right: 1px solid #23242B;

    div {
      width: 16px;
      height: 16px;
      input {
        width: 100%;
        height: 100%;
        transform: scale(1);
      }
      input:checked + label:after {
        top: -2px;
        left: 7px;
        width: 5px;
        height: 15px;
      }
      label:before {
        padding: 6px;
      }
    }
  }
}
