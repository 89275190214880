@import '../../assets/css/base/media-queries';
@import '../../assets/css/base/mixin';

.my-account {
  padding: 120px 0 108px;
  position: relative;
  background: url("../../assets/images/somebg.png") 100% 100% no-repeat;
  @include breakpoint-down(lg) {
    background: unset;
    padding: 90px 0 50px;
  }

  .design-elements {
    position: absolute;
    //position: relative;s
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @include breakpoint-down(lg) {
      display: none;
    }
    img {
      position: absolute;
      width: 536px;
      top: 88px;
      left: 0;
    }
    /*.elipse {
      position: absolute;
      width: 869px;
      height: 869px;
      left: 240px;
      top: calc(50% - 869px/2 + 24.5px);
      background: radial-gradient(50% 50% at 50% 50%, #332011 0%, rgba(16, 17, 27, 0) 100%);
      opacity: 0.8;
    }*/
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .sidebar {
    @apply bg-card-bg;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    @include breakpoint-down(lg) {
      box-shadow: unset;
      border-radius: 0;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      gap: 8px;
      background: unset;
      margin: 0 -16px 32px;
      padding: 0 16px;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-item {
      padding: 12px 16px;
      border-bottom: 1px solid #23242B;
      @apply text-secondary;
      @include breakpoint-down(lg) {
        @apply bg-card-bg;
        border-bottom: 1px solid #23242B;
        border-radius: 16px;
        flex: 1 0 auto;
      }
      &:last-child {
        border-bottom: unset;
      }

      a {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          @include breakpoint-down(lg) {
            font-size: 14px;
            line-height: 24px;
          }
        }
        .notification {
          position: absolute;
          right: 0;
          @apply bg-orange;
          backdrop-filter: blur(22px);
          border-radius: 24px;
          padding: 4px 8px;
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: #fff !important;
          @include breakpoint-down(lg) {
            position: relative;
            margin-left: 8px;
          }
        }
        svg {
          margin-right: 8px;
          @include breakpoint-down(lg) {
            width: 16px;
          }
        }
        &.active {
          span {
            @apply text-orange;
          }
          svg path {
            fill: #CD702C;
          }
        }
      }
    }
  }
}

.account-section {
  @apply bg-card-bg;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  margin-bottom: 40px;
  &.history {
    .container {
      padding: 0;

    }



    .pagination {
      display: flex;
      gap: 12px;
      padding: 8px 0;
      justify-content: center;
      flex-wrap: wrap;

      p {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        width: 40px;
        height: 40px;
        border: 1px solid #CD702C;
        backdrop-filter: blur(22px);
        border-radius: 24px;
        color: #CD702C;
        transition: all 0.5s;
        background: transparent;

        &.active, &:hover {
          background: linear-gradient(258.27deg, #D0B056 0%, #CD6A28 100%);
          backdrop-filter: blur(22px);
          color: #FFFFFF;
        }
      }
    }
  }
  @include breakpoint-down(lg) {
    margin-bottom: 32px;

    &.history {
      .container {
        padding: 0 16px;

      }
    }
  }

  &-title {
    padding: 16px 24px;
    border-bottom: 1px solid #23242B;
    @include breakpoint-down(lg) {
      font-weight: 800;
      font-size: 16px;
      line-height: 28px;
      padding: 12px 16px;
    }
  }

  &-body {
    padding: 16px 24px;
    @include breakpoint-down(lg) {
      padding: 16px;
    }
    .copy-btn {
      flex: 0 0 105px;
      @include breakpoint-down(lg) {
        width: 105px;
        flex: auto;
      }
    }

    .account-not-confirmed {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      @apply text-orange-light;
      display: flex;
      flex-direction: row;
      padding-top: 25px;
      svg {
        width: 32px;
        flex: 0 0 32px;
        margin-right: 8px;
      }
    }
  }
}

.downloads {
  display: flex;

  &-item {
    @include breakpoint-down(lg) {
      margin-bottom: 16px;
    }
    &-title {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #BCB8E3;
      display: block;
      margin-bottom: 8px;
    }

    &-count {
      font-weight: 300;
      font-size: 32px;
      line-height: 36px;
      color: #BCB8E3;
      display: block;
    }

    &:nth-child(2) {
      .downloads-item-count {
        color: #CD702C;
      }
    }

    &:nth-child(3) {
      .downloads-item-count {
        font-weight: 1000;
        color: #34B2C5;
      }
    }
  }
}

.password-check {
  padding: 8px 24px 24px;
  @include breakpoint-down(lg) {
    padding: 8px 10px 14px;
  }

  label {
    @apply text-primary;
  }
}
