@mixin gradient-text () {
  background: linear-gradient(258.27deg, #D0B056 0%, #CD6A28 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@mixin gradient-text-portfolio () {
  background: linear-gradient(244.36deg, #E9CDBB 24.03%, #C6BFE2 83.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
