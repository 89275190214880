@import '../../assets/css/base/media-queries';

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  transition: height 0.5s ease-in;
  height: 88px;
  display: flex;
  @include breakpoint-down(md) {
    padding: 15px 0;
    height: 64px;
  }

  &-default {
    //padding: 0;
  }

  .navbar-brand {
    position: relative;
    z-index: 999;

    &-logo {
      width: 111px;
      @include breakpoint-down(lg) {
        width: 92px;
      }
      @media (max-width: 343px) {
        width: 70px;
      }
    }
  }

  .navbar-toggle {
    position: relative;
    z-index: 999;
  }

  ul {
    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: right;
      @apply text-secondary;
      a {
        @apply text-secondary;
        padding: 6px 16px;
        line-height: inherit;
        border-radius: 22px;
        transition: background-color 0.5s;
        @include breakpoint-down(lg) {
          padding: 6px 9px;
        }

        &:hover, &.active {
          @apply text-primary;
        }
      }
    }
  }

  @include breakpoint-down(md) {
    .nav-collapse {
      @apply bg-card-highlight;
      position: absolute;
      top: 100%;
      //bottom: 0;
      left: 0;
      right: 0;
      z-index: 998;
      transition: visibility 0.5s;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      ul {
        z-index: 1;
        position: relative;

        li {
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          padding: 12px 16px;
          border-bottom: 1px solid #1D1E26;

          &:last-child {
            border-bottom: unset;
          }

          opacity: 0;
          transform: translate3d(-40px, 0, 0);
          -webkit-transform: translate3d(-40px, 0, 0);
          transition: transform .3s, opacity 0.3s;
          -webkit-transition: -webkit-transform 0.3s ease 0s, opacity 0.2s ease 0s;
          will-change: transform, opacity;
          //animation-timing-function: cubic-bezier(.45, .005, 0, 1);
          @for $i from 0 to 15 {
            &.delay-#{$i + 1} {
              transition-delay: #{$i * 0.1}s;
              -webkit-transition-delay: #{$i * 0.1}s;
            }
          }
        }
      }

      &.active {
        ul {
          li {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }

  .dropdown-avatar {
    position: relative;

    &-block {
      position: absolute;
      @apply bg-card-highlight;
      padding: 0 0 12px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      width: 284px;
      right: 0;
      top: calc(100% + 4px);

      visibility: hidden;

      &.active {
        visibility: visible;
      }

      &-info {
        border-bottom: 1px solid #23242B;
        text-align: center;

        &-title {
          padding: 16px 16px 8px;
          border-bottom: 1px solid #23242B;
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          text-align: left;
          @apply text-secondary;
        }

        &-details {
          padding: 16px 16px 12px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 4px;
          justify-content: space-around;

          .dropdown-avatar-block-info-detail {
            span {
              font-weight: 1000;
              font-size: 24px;
              line-height: 32px;
              @apply text-orange;
              display: block;

              &:first-child {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                @apply text-secondary;
              }
            }

            &:nth-child(2) {
              span {
                @apply text-blue-light;
                &:first-child {
                  @apply text-secondary;
                }
              }
            }
          }
        }

        .btn {
          margin-bottom: 16px;
        }
      }

      &-menu {
        a {
          padding: 12px 16px 12px 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          @apply text-secondary;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            @include breakpoint-down(lg) {
              font-size: 14px;
              line-height: 24px;
            }
          }

          .notification {
            @apply bg-orange;
            backdrop-filter: blur(22px);
            border-radius: 24px;
            padding: 4px 8px;
            //position: absolute;
            font-size: 12px;
            line-height: 16px;
            margin-left: 12px;
            @apply text-primary;
          }

          svg {
            margin-right: 12px;
            @include breakpoint-down(lg) {
              width: 16px;
            }
          }

          &.active {
            span {
              @apply text-orange;
            }

            svg path {
              fill: #CD702C;
            }
          }
        }
      }
    }

    .avatar-button {
      @apply bg-card-bg;
      backdrop-filter: blur(22px);
      border-radius: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 12px;
      cursor: pointer;

      &-downloads {
        padding: 0px 0px 0px 12px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-align: right;
        @apply text-secondary;
        @include breakpoint-down(lg) {
          font-size: 12px;
          line-height: 12px;
        }

        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          text-align: right;
          @apply text-orange;
          @include breakpoint-down(lg) {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }

      &-avatar {
        @apply bg-card-highlight-2;
        backdrop-filter: blur(22px);
        padding: 8px;
        border-radius: 24px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        @apply text-orange-light;
        @include breakpoint-down(lg) {
          padding: 8px 6px;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
}

header.is-sticky {
  .nav {
    @apply bg-main-bg;
    height: 64px;
  }
}

footer {
  padding: 64px 0;
  position: relative;
  @include breakpoint-down(lg) {
    padding: 32px 0;
    overflow: hidden;
  }

  .design-element {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    @include breakpoint-down(lg) {
      transform: translate(40%, 40%);
    }

    img {
      width: 532px;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .footer-contact-block {
    margin: 48px 0;

    .email {
      display: flex;
      margin-bottom: 12px;

      &-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #BCB8E3;
        flex: 0 0 80px;
      }

      &-link {
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        color: #BCB8E3;
      }
    }

    .telegram {
      background: #1D1E26;
      border: 1px solid #23242B;
      border-radius: 16px;
      padding: 12px;
      display: flex;

      img {
        margin-right: 8px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #BCB8E3;

        a {
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          color: #CD702C;
          display: block;
        }
      }
    }
  }

  .footer-copy {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #64627A;
    margin-top: auto;
    @include breakpoint-down(lg) {
      margin-top: 56px;
    }
  }
}
