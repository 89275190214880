@import '../../../../assets/css/base/media-queries';
.modalHeader {
  display: flex;
  justify-content: center;
  position: relative;
  .modalTitle {
    font-weight: 1000;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    @include breakpoint-down(lg) {
      font-size: 20px;
      line-height: 32px;
    }
  }
  .modalClose {
    cursor: pointer;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    svg path {
      fill: #64627A;
    }
    &:hover {
      svg path {
        fill: #BCB8E3;
      }
    }
  }
}
