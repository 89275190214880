@import "../../assets/css/base/media-queries";
.success-text {
  text-align: center;
  h4 {
    font-weight: 700;
    margin-bottom: 16px;
  }
  svg {
    margin: 0 auto 16px;
  }
  p {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    @include breakpoint-down(lg) {
      font-size: 13px;
      line-height: 16px;
    }
  }
  .btn {
    min-width: 172px !important;
  }
}
