.joinTelegramContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 55px;
  }
  p{
    font-size: 20px;
    margin-top: 12px;

  }

}
