.form-group {
  margin-bottom: 16px;
  width: 100%;
  position: relative;
  &.form-group-hidden {
    height: 0;
    width: 0;
  }
  label {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #BCB8E3;
    margin-bottom: 8px;
    display: block;
  }
  .form-control {
    width: 100%;
    @apply bg-main-bg;
    border: 1px solid;
    @apply border-card-highlight;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    @apply text-primary;
    padding: 8px 12px;
    &:focus, &:active {
      outline: unset;
      box-shadow: unset;
    }
    &.notValid {
      border: 1px solid theme('colors.orange');
    }
    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      @apply text-secondary;
    }
  }
  textarea.form-control {
    min-height: 156px;
    resize: unset;
  }
  .inputError {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    @apply text-main-bg;
    padding: 8px 12px;
    border-radius: 8px;
    @apply bg-neutral-gray;
    position: absolute;
    top: calc(100% - 4px);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    z-index: 999;
    display: none;
    &:after {
      content: " ";
      position: absolute;
      bottom: calc(100% - 1px);
      left: 50%;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent theme('colors.neutral-gray') transparent;
    }
  }
  .form-control:focus {
    + .inputError {
      display: block;
    }
  }
  &.input-light {
    .form-control {
      @apply bg-card-highlight;
    }
  }
}

/*

.inputContainer {
  box-sizing: border-box;
  width: 100%;
}

.disabledInput {
  opacity: 0.5;
}

.inputControl {
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  color: #35363f;
  font-size: 14px;
  outline: none;
  z-index: 1;
  border-radius: 4px;
  background-color: #fefefe;
  border: 1px solid #d6daec;
  padding: 10px 12px;
  border-radius: 3px;
  font-family: "Roboto";
  &:focus {
    border: 1px solid #8c91b8;
    outline: none;
  }
  &:disabled {
    background-color: #fbfcff;
    opacity: 50%;
  }
}

.withIcon {
  padding-right: 45px;
}

.fieldIcon {
  position: absolute;
  right: 15px;
  z-index: 2;
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 18px;
  color: #435ad1;
}

.inputError {
  font-size: 12px;
  color: #da3a3a;
  position: absolute;
  margin-top: 5px;
  text-align: left;
  line-height: 15px;
}

.notValid {
  border-color: #da3a3a;
}

.selectControl {
  background-color: transparent;
  min-height: 42px;
  font-size: 14px;
  padding: 0;
  border-radius: 4px;

  > div {
    border-color: #e9ecfa;
    min-height: 42px;
  }
  div {
    background-color: #fefefe;
    color: #35363f;
    border-radius: 4px;

    &[class*="select__control--is-focused"] {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
    div[class*="option--is-selected"] {
      background-color: #dddfe4;
    }
    div[class*="option--is-focused"] {
      background-color: #ebf1fd;
    }
  }
}
*/
