@import '../../../assets/css/base/media-queries';

.modalCover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transform: translateZ(0);
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
  &.positionBottom {
    align-items: flex-end;
  }
  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.88);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .modalArea {
    width: 416px;
    height: 100%;
    min-height: 179px;
    opacity: 1;
    overflow: auto;
    z-index: 1001;
    position: relative;
    background: #1D1E26;
    border-radius: 24px;
    padding: 24px;
    @media screen and (min-width: 250px) {
        height: auto;
        max-width: 1000px;
        max-height: 90vh;
    }
    @include breakpoint-down(xs) {
      width: 100%;
      margin: 0 8px;
      padding: 12px 16px;
    }
    .body {
      padding: 32px 24px 24px;
      @include breakpoint-down(xs) {
        padding: 24px 0 12px;
      }
    }
  }
}
