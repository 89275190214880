@import '../../assets/css/base/media-queries';
@import '../../assets/css/base/mixin';

.home-banner {
  height: 720px;
  display: flex;
  align-items: flex-end;
  position: relative;
  //padding-bottom: 56px;
  @include breakpoint-down(lg) {
    height: auto;
    padding-top: 96px;
    padding-bottom: 48px;
    overflow: hidden;
  }

  .design-elements {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

    img {
      top: -57%;
      position: absolute;
      transform: rotate(95deg);
      left: -10%;
      width: 500px;
      z-index: 2;
      @include breakpoint-down(lg) {
        width: 400px;
        left: -40%;
        top: -65%;
      }
    }

    .elipse {
      position: absolute;
      width: 941px;
      height: 941px;
      left: -159px;
      top: -159px;
      background: radial-gradient(50% 50% at 50% 50%, #1D2230 0%, rgba(27, 28, 36, 0) 100%);
      z-index: 1;
      @include breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  h1, h4 {
    margin-bottom: 52px;
    padding-left: 24px;
    @include breakpoint-down(lg) {
      padding-left: 16px;
    }
  }

  h4 {
    max-width: 55%;
    @apply text-secondary;
  }

  h1, h4, .btn {
    z-index: 2;
    position: relative;
  }

  img {
    width: 100%;
    @include breakpoint-down(lg) {
      position: absolute;
      bottom: 83px;
      right: -112px;
      z-index: 0;
      width: 324px;
      height: 318px;
    }
  }
}

.why-xpass {
  padding: 64px 0;
  @include breakpoint-down(lg) {
    padding: 32px 0;
  }
  position: relative;

  .design-elements {
    position: absolute;
    //position: relative;s
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    img {
      position: absolute;
      width: 536px;
      top: -90px;
      left: 0;
      @include breakpoint-down(lg) {
        top: -20px;
      }
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .section-title {
    margin-bottom: 48px;
    margin-left: 24px;
    @include breakpoint-down(lg) {
      margin-left: 16px;
      margin-bottom: 32px;
    }
  }

  .advantages-item {
    @apply bg-card-bg;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    padding: 48px 32px;
    transition: 0.5s;
    @include breakpoint-down(lg) {
      width: 260px;
      padding: 32px 24px;
    }

    img {
      width: 104px;
      height: 104px;
      margin-bottom: 32px;
      @include breakpoint-down(lg) {
        width: 80px;
        height: 80px;
        margin-bottom: 24px;
      }
    }

    .title {
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      @apply text-secondary;
      transition: 0.5s;
      @include breakpoint-down(lg) {
        font-size: 20px;
        line-height: 32px;
        font-weight: 1000;
      }
    }

    &:hover {
      @apply bg-card-highlight;
      .title {
        @apply text-orange-light;
      }
    }

  }
}

.databases-section {
  padding: 68px 0 64px;
  text-align: center;
  background: url('../../assets/images/databases-bg.jpg') center/100% auto no-repeat;
  @include breakpoint-down(lg) {
    padding: 32px 0;
  }

  .section-title {
    margin-bottom: 48px;
    text-align: center;
    @include breakpoint-down(lg) {
      margin-left: 16px;
      margin-bottom: 32px;
      text-align: left;
    }
  }

  .btn {
    margin-top: 60px;
  }
}

.api-section {
  padding: 0 0 64px;
  @include breakpoint-down(lg) {
    padding: 0 0 32px;
  }
  position: relative;

  .design-elements {
    position: absolute;
    //position: relative;s
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .elipse {
      position: absolute;
      width: 657px;
      height: 657px;
      right: calc(50% - 657px / 2);
      top: calc(50% - 657px / 2);
      background: radial-gradient(50% 50% at 50% 50%, #152530 0%, rgba(20, 29, 41, 0.44) 57.82%, rgba(16, 17, 27, 0) 100%);
      opacity: 0.8;
      @include breakpoint-down(lg) {
        width: 482px;
        height: 482px;
        right: 0;
        bottom: -160px;
        top: unset;
      }
    }
  }

  .container {
    position: relative;
    z-index: 2;
    .row {
      .col-12:first-child {
        position: relative;
        z-index: 2;
      }
    }
  }

  .section-title {
    margin: 64px 0 48px;
    @include breakpoint-down(lg) {
      margin: 32px 0 48px;
    }
  }

  .api-section-content {
    padding-bottom: 48px;

    p {
      @apply text-secondary;
      margin-bottom: 24px;
    }

    ul {
      list-style: none;

      li {
        background: url("../../assets/images/icons/check.svg") top left/32px 32px no-repeat;
        min-height: 32px;
        padding-left: 51px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        @apply text-secondary;
        margin-bottom: 24px;
        @include breakpoint-down(lg) {
          font-size: 14px;
          line-height: 24px;
          background-size: 24px 24px;
          padding-left: 40px;
          margin-bottom: 8px;
        }
      }
    }
  }

  .api-section-image {
    //position: relative;
    //height: 100%;
    //width: 100%;
    img {
      //position: absolute;
      position: relative;
      width: 100%;
      max-width: 140%;
      right: -64px;
      top: 0;
      @include breakpoint-down(lg) {
        width: 100%;
        right: 0;
        max-width: 600px;
      }
    }
  }

  button {
    @include breakpoint-down(lg) {
      min-width: 104px !important;
    }
  }
}

.get-started-section {
  padding: 64px 0;
  background: url("../../assets/images/somebg2.png") 0 50%/100% 100% no-repeat;
  @include breakpoint-down(lg) {
    padding: 32px 0;
    background: unset;
  }
  position: relative;

  .design-elements {
    position: absolute;
    //position: relative;s
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    img {
      position: absolute;
      width: 536px;
      top: -90px;
      left: 0;
      @include breakpoint-down(lg) {
        left: -70px;
        top: 60px;
        width: 388px;
      }
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .section-title {
    margin-bottom: 86px;
    @include breakpoint-down(lg) {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .range-slider {
    margin-bottom: 38px;
    @include breakpoint-down(lg) {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 46px;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      @include breakpoint-down(lg) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 26px;
      }
    }

    .rc-slider {
      margin-bottom: 84px;
      height: unset;
      padding: unset;
      @include breakpoint-down(lg) {
        margin-bottom: 66px;
      }
      .rc-slider-rail {
        height: 8px;
        background: linear-gradient(90deg, #CD702C 0%, #9747FF 51.35%, #1F7AC8 100%);
        border-radius: 4px;
        display: block;
        position: relative;
        touch-action: none;
      }
      .rc-slider-track {
        height: 100%;
        background: rgba(29, 30, 38, 0.7);
        border-radius: 4px;
        top: 0;
        display: block;
      }
      .rc-slider-step {
        visibility: hidden;
      }
      .rc-slider-handle {
        width: 28px;
        height: 28px;
        background: #D9D9D9;
        border-radius: 30px;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        cursor: pointer;
        display: inline-block;
        position: absolute;
        touch-action: none;
        opacity: 1;
        margin-top: -14px;
        border: unset;
        &.rc-slider-handle-dragging {
          box-shadow: unset;
          border: unset;
        }

        &::after {
          content: unset;
        }
      }
      .rc-slider-mark {
        .rc-slider-mark-text {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          color: #FFFFFF;
          &:first-child {
            transform: unset !important;
          }
          &:last-child {
            transform: translateX(-100%) !important;
          }
        }
      }
    }
  }

  .btcpay-info {
    p {
      @apply text-secondary;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
}

.container {
  position: relative;
  z-index: 2;
}
