@import '../../assets/css/base/media-queries';
@import '../../assets/css/base/mixin';

.api-reference-page {
  padding: 130px 0 70px;
  @include breakpoint-down(lg) {
    padding: 80px 0 70px;
  }

  .container {
    z-index: 3;
    .infoText {
      color: #CD702C;
      margin-top: 12px;
      text-align: center;
      font-size: 14px;
    }
  }

  .selected-filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    margin-bottom: 32px;
    @include breakpoint-down(lg) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: auto;
      margin: 8px -16px 16px;
      padding: 0 16px;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .selected-filter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 2px;
      @apply bg-card-highlight;
      backdrop-filter: blur(22px);
      border-radius: 24px;
      padding: 6px 6px 6px 12px;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      @apply text-secondary;
      svg {
        width: 20px;
        height: 20px;
      }

      @include breakpoint-down(lg) {
        font-size: 12px;
        line-height: 16px;
        padding: 6px 6px 6px 10px;
        gap: 4px;
        flex: 1 0 auto;
        svg {
          width: 16px;
          height: 16px;
        }
      }

    }
  }

  .dropdown-filter {
    position: relative;

    .filter-btn {
      @apply bg-card-bg;
      backdrop-filter: blur(22px);
      border-radius: 8px;
      padding: 12px 24px 12px 12px;
      display: flex;
      gap: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      @apply text-secondary;
      transition: .5s;
      @include breakpoint-down(lg) {
        padding: 8px 12px 8px 8px;
        border: 1px solid #2D2E38;
      }

      svg path {
        transition: .5s;
      }

      &.filtered {
        @apply text-blue;
        svg path {
          fill: theme('colors.blue');
        }
      }

      &.active {
        @apply text-orange-light;
        svg path {
          fill: theme('colors.orange-light');
        }
      }
    }

    &-block {
      position: absolute;
      z-index: 99;
      @apply bg-card-highlight;
      padding: 0;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.24);
      border-radius: 8px;
      width: 480px;
      right: 0;
      top: calc(100% + 10px);
      visibility: hidden;
      @include breakpoint-down(lg) {
        width: calc(100vw - 32px);
        max-width: 480px;
      }

      &.active {
        visibility: visible;
      }

      &-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        @apply text-primary;
        padding: 16px 16px 12px;
        border-bottom: 1px solid #1D1E26;
      }

      &-content {
        padding: 12px 16px 16px;

        .row {
          overflow: unset;
        }

        label {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          @apply text-secondary;
          padding-left: 12px;
          margin-bottom: 8px;
          display: block;
        }

        .react-dropdown-select {
          @apply bg-card-bg;
          border: 1px solid theme('colors.card-bg');
          border-radius: 8px;
          padding: 8px 8px 8px 12px;
          margin-bottom: 20px;

          @include breakpoint-down(lg) {
            margin-bottom: 16px;
          }

          &:hover, &:focus-within {
            background: #2D2E38;
            border: 1px solid #23242B;
            box-shadow: unset;
          }

          &-content {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            @apply text-secondary;
          }

          &-dropdown-handle {
            top: 8px;
            position: absolute;
            right: 8px;

            svg, svg:hover {
              width: 24px;
              height: 24px;

              path {
                fill: theme('colors.secondary');
                stroke: theme('colors.secondary');
              }
            }
          }

          &[aria-expanded='true'] {
            svg {
              path {
                fill: theme('colors.orange-light');
                stroke: theme('colors.orange-light');
              }
            }
          }

          &-dropdown {
            @apply bg-card-highlight;
            border: 1px solid theme('colors.card-highlight-2');
            box-shadow: 0 8px 24px rgba(0, 0, 0, 0.24);
            border-radius: 8px;
            @include breakpoint-down(lg) {
              //top: 80px;
            }

            .react-dropdown-select-item {
              @apply bg-card-highlight;
              border-bottom: 1px solid #1D1E26;
              padding: 12px 16px;

              &:last-child {
                border-bottom: unset;
              }

              &:hover, &.react-dropdown-select-item-selected {
                background: #2D2E38;
              }
            }
          }
        }
      }

    }
  }

  .pagination {
    display: flex;
    gap: 12px;
    padding: 8px 0;
    justify-content: center;
    flex-wrap: wrap;

    p {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      width: 40px;
      height: 40px;
      border: 1px solid #CD702C;
      backdrop-filter: blur(22px);
      border-radius: 24px;
      color: #CD702C;
      transition: all 0.5s;
      background: transparent;

      &.active, &:hover {
        background: linear-gradient(258.27deg, #D0B056 0%, #CD6A28 100%);
        backdrop-filter: blur(22px);
        color: #FFFFFF;
      }
    }
  }

  .status-bar-download {
    background: #23242B;
    border-bottom: 1px solid #23242B;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.16);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    //padding: 16px 0;
    &-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      @include breakpoint-down(lg) {
        //align-items: flex-end;
      }
      .download-cancel {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        @apply text-secondary;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        svg {
          width: 24px;
          height: 24px;
        }
        @include breakpoint-down(lg) {
          //margin-bottom: 8px;
        }
      }

      .download-formats {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 8px 0;
        gap: 16px;
        @include breakpoint-down(lg) {
          flex-wrap: wrap;
          p, .btn {
            flex: 1 0 auto;
          }
          p {
            flex: 0 0 100%;
          }
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          @apply text-secondary;
        }
      }
    }
  }
}

.css-9ma7ck-OptionComponent {
   background: transparent !important;
}
