@import '../../assets/css/base/media-queries';
@import '../../assets/css/base/mixin';

.pack-card {
  @apply bg-card-highlight;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  padding: 88px 32px 48px;
  position: relative;
  @include breakpoint-down(lg) {
    padding: 56px 20px 24px;
    margin-bottom: 32px;
  }

  .pack-badge {
    background: linear-gradient(258.27deg, #D0B056 0%, #CD6A28 100%);
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    position: absolute;
    left: -8px;
    top: 32px;
    padding: 12px 40px 12px 32px;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 8px 8px 8px 0;
    @include breakpoint-down(lg) {
      padding: 6px 20px 6px 16px;
      font-size: 16px;
      line-height: 28px;
      top: 16px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      left: 3px;
      bottom: -8px;
      background: linear-gradient(258.27deg, #815119 0%, #CD6A28 100%);
      transform: rotate(45deg);
      z-index: -1;
      //border-top: 8px solid #32557f;
      //border-left: 8px solid transparent;
      //border-top-image: linear-gradient(258.27deg, #815119 0%, #CD6A28 100%);
    }
    &:before {
      content: '';
      position: absolute;
      @apply bg-card-highlight;
      top: 0px;
      width: 56px;
      border-radius: 4px;
      transform: rotate(45deg);
      right: -40px;
      height: 56px;
      @include breakpoint-down(lg) {
        width: 30px;
        height: 30px;
        right: -19px;
        top: 5px;
      }
    }
    span {
      font-weight: 800;
    }
  }

  &.pack-bordered {
    border: 1px solid #CD702C;
  }

  &.pack-blue {
    &.pack-bordered {
      border: 1px solid #1B5C8C;
    }
    .pack-badge {
      background: linear-gradient(258.27deg, #35B4C6 0%, #1E77C9 100%);
      &:after {
        background: linear-gradient(258.27deg, #1B5C8C 0%, #1E77C9 100%);
      }
    }
  }

  &.pack-purple {
    &.pack-bordered {
      border: 1px solid #472474;
    }
    .pack-badge {
      background: linear-gradient(258.27deg, #DD4BE0 0%, #5B3199 100%);
      &:after {
        background: linear-gradient(258.27deg, #472474 0%, #5B3199 100%);
      }
    }
  }

  .pack-price-wrapper {
    padding: 36px 0 28px;
    display: flex;
    justify-content: space-between;
    @include breakpoint-down(lg) {
      padding: 16px 0 12px;
    }

    .pack-price-count {
      position: relative;
      .pack-price-number {
        display: block;
        font-weight: 1000;
        font-size: 48px;
        line-height: 52px;
        margin-bottom: 4px;
        padding: 12px 12px 0;
        @include breakpoint-between(lg, xl) {
          font-size: 32px;
          line-height: 36px;
        }
        @include breakpoint-down(lg) {
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 0;
          padding: 8px 8px 0;
        }
        & + .pack-price-text {
          position: unset;
          top: unset;
          padding-bottom: 12px;
          @include breakpoint-down(lg) {
            padding-bottom: 8px;
            top: unset;
          }
        }
      }

      .pack-price-text {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        position: absolute;
        padding-left: 12px;
        top: 68px;
        @include breakpoint-between(lg, xl) {
          font-size: 14px;
          line-height: 20px;
        }
        @include breakpoint-down(lg) {
          font-size: 13px;
          line-height: 24px;
          top: 44px;
          padding-left: 8px;
        }
      }

      .react-dropdown-select {
        border: 1px solid #2D2E38;
        border-radius: 8px;
        //height: 108px;
        padding: 12px 12px 42px;
        min-width: 152px;
        //margin-top: -12px;
        @include breakpoint-between(lg, xl) {
          min-width: 132px;
        }
        @include breakpoint-down(lg) {
          padding: 8px 8px 32px;
          min-width: 144px;
          //margin-top: -8px;
        }
        &:hover, &:focus-within {
          background: #2D2E38;
          border: 1px solid #23242B;
          box-shadow: unset;
        }
        &-content {
          font-weight: 1000;
          font-size: 48px;
          line-height: 52px;
          color: #FFFFFF;
          @include breakpoint-between(lg, xl) {
            font-size: 32px;
            line-height: 36px;
          }
          @include breakpoint-down(lg) {
            font-size: 32px;
            line-height: 36px;
          }
        }
        &-dropdown-handle {
          margin-top: 30px;
          @include breakpoint-down(lg) {
            margin-top: 12px;
          }
          svg, svg:hover {
            width: 24px;
            height: 24px;
            path {
              fill: #64627A;
              stroke: #64627A;
            }
          }
        }

        &-dropdown {
          background: #23242B;
          border: 1px solid #2D2E38;
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.24);
          border-radius: 8px;
          top: 110px;
          @include breakpoint-down(lg) {
            top: 80px;
          }
          .react-dropdown-select-item {
            background: #23242B;
            border-bottom: 1px solid #1D1E26;
            padding: 8px 16px;
            &:last-child {
              border-bottom: unset;
            }
            &:hover, &.react-dropdown-select-item-selected {
              background: #2D2E38;
            }
          }
        }
      }
    }

    .pack-price {
      font-weight: 1000;
      font-size: 48px;
      line-height: 52px;
      padding-top: 12px;
      @include breakpoint-between(lg, xl) {
        font-size: 32px;
        line-height: 36px;
      }
      @include breakpoint-down(lg) {
        font-size: 32px;
        line-height: 36px;
        padding-top: 8px;
      }
      &.free {
        @include gradient-text();
      }
      span {
        font-weight: 300;
        display: block;
        text-align: right;
        &:nth-child(1) {
          @include gradient-text();
        }
        &:nth-child(2) {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: #BCB8E3;
          margin-top: 4px;
          @include breakpoint-between(lg, xl) {
            font-size: 14px;
            line-height: 20px;
          }
          @include breakpoint-down(lg) {
            display: none;
          }
        }
      }
    }
  }

  .pack-includes {
    padding: 36px 0 32px;
    border-top: 1px dashed #64627A;
    @include breakpoint-down(lg) {
      padding: 16px 0;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      background: url("../../assets/images/icons/x_light.svg") top left/32px 32px no-repeat;
      padding-left: 44px;
      margin-bottom: 12px;
      @include breakpoint-down(lg) {
        font-size: 16px;
        line-height: 24px;
        background-size: 24px 24px;
        padding-left: 36px;
      }

      &.active {
        background-image: url("../../assets/images/icons/check.svg");
      }
    }
  }

  .btn {
    min-width: 200px;
    margin: 0 auto;
    display: block;
  }
}
