.checkBoxControl {
  position: relative;
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    &:before {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    &:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    position: absolute;
    transform: scale(2);
    opacity: 0;
    z-index: 500;
    cursor: pointer;
    &:checked {
      + {
        label {
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: -3px;
            left: 9px;
            width: 7px;
            height: 17px;
            border: solid theme('colors.main-bg');
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
          &:before {
            border: 2px solid theme('colors.blue');
            background: #1F7AC8;
          }
        }
      }
    }
    &:indeterminate + label {
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        background: #64627A;
      }
      &:before {
        border: 2px solid #64627A;
        background: transparent;
      }
    }
  }
  label {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    @apply text-secondary;
    position: relative;
    cursor: pointer;
    display: block;
    padding-left: 32px;
    &:before {
      content: "";
      -webkit-appearance: none;
      border-radius: 2px;
      border: 2px solid theme('colors.card-highlight-2');
      //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      left: 0px;
      position: absolute;
    }
  }
}
