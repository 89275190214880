@charset "UTF-8";

//Globals
@import "base/variables";
@import "base/mixin";
@import "base/media-queries";
@import 'base/utilites';
@import 'base/fonts';
@import 'base/globals';
@import 'base/keyframes';
@import 'base/table';

// Components
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'base/typo';
