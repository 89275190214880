@import '../../assets/css/base/media-queries';
@import '../../assets/css/base/mixin';

.api-reference-page {
  padding: 92px 0 70px;

  .sidebar-wrap {
    @include breakpoint-up(lg) {
      &.is-sticky {
        position: fixed;
        top: 100px;
        z-index: 999;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
      }
    }
  }

  .sidebar {
    @apply bg-card-bg;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    @include breakpoint-down(lg) {
      box-shadow: unset;
      border-radius: 0;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      gap: 8px;
      background: unset;
      margin: 0 -16px;
      padding: 0 16px;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-item {
      padding: 12px 16px;
      border-bottom: 1px solid #23242B;
      @include breakpoint-down(lg) {
        @apply bg-card-bg;
        border-bottom: 1px solid #23242B;
        border-radius: 16px;
        flex: 1 0 auto;
      }

      a {
        display: flex;
        flex-direction: row;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        @apply text-secondary;
        @include breakpoint-down(lg) {
          font-size: 14px;
          line-height: 24px;
        }

        &.active {
          @apply text-orange;
        }
      }

      &:last-child {
        border-bottom: unset;
      }
    }
  }

  .api-reference-section {
    &-title {
      border-bottom: 1px solid #23242B;
      padding: 16px 24px;
      @include breakpoint-down(lg) {
        padding: 16px 0;
      }
    }

    &-body {
      padding: 16px 24px;
      @include breakpoint-down(lg) {
        padding: 16px 0;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        @apply text-primary;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      ul {
        list-style: none;
        padding-left: 27px;
        @include breakpoint-down(lg) {
          padding-left: 15px;
        }
        li {
          background: url("../../assets/images/icons/check.svg") top left/24px 24px no-repeat;
          padding-left: 40px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          @apply text-primary;
          margin-bottom: 20px;
          @include breakpoint-down(lg) {
            font-size: 14px;
            margin-bottom: 8px;
          }
        }
      }
      ol {
        list-style: decimal;
        padding-left: 57px;
        @include breakpoint-down(lg) {
          padding-left: 35px;
        }
        li {
          padding-left: 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          @apply text-primary;
          margin-bottom: 20px;
          @include breakpoint-down(lg) {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
