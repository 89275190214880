@import '../../assets/css/base/media-queries';
.not-found-page {
  padding: 152px 0 0;
  img {
    margin-top: 68px;
    @include breakpoint-down(lg) {
      margin-top: 32px;
    }
  }
  background: url("../../assets/images/somebg2.png") 0 50%/100% auto no-repeat;
  @include breakpoint-down(lg) {
    padding: 80px 0 36px;
    background: unset;
  }
  position: relative;
  .design-elements {
    position: absolute;
    //position: relative;s
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    img {
      position: absolute;
      width: 536px;
      top: 30px;
      left: 0;
      @include breakpoint-down(lg) {
        left: -70px;
        top: 60px;
        width: 388px;
      }
    }
  }
  .container {
    position: relative;
    z-index: 2;
  }
}
